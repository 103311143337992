import { Component, OnInit, OnDestroy } from '@angular/core';

import { Md5 } from 'ts-md5/dist/md5';
import { Subscription } from 'rxjs';

import { StatusService } from 'src/app/services/status.service';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-permanence',
  templateUrl: './permanence.component.html',
  styleUrls: ['./permanence.component.scss'],
})
export class PermanenceComponent implements OnInit, OnDestroy {
  private statusSub: Subscription;
  public dutylist = [];
  public sapDutyList = [];
  public incsaDutyList = [];

  constructor(private statusService: StatusService) {}

  ngOnInit(): void {
    this.statusSub = this.statusService.status.subscribe((status) => {
      //this.status = status;
      //console.log(status);
      //console.log('Processing...');
      if ('dutylist' in status) {
        this.dutylist = status.dutylist;
        //console.log('Found dutylist with ' + this.dutylist.length + ' services');
        this.sapDutyList = [];
        this.incsaDutyList = [];
        this.dutylist.forEach((service) => {
          if (service.servicetype == 1) {
            this.sapDutyList.push(service);
          } else {
            this.incsaDutyList.push(service);
          }
          //cosensole.log('Service vvv');
          //console.log(service);
        });
      }
    });
    this.statusService.fetchStatus().subscribe();
    setInterval(() => {
      this.statusService.fetchStatus().subscribe();
    }, environment.dutyListUpdateTime * 1000);
  }

  ngOnDestroy(): void {
    this.statusSub.unsubscribe();
  }

  getGravatarImage(email) {
    var hash;
    if (email) {
      hash = Md5.hashStr(email.toLowerCase());
    }
    const image = 'https://www.gravatar.com/avatar/' + hash + '?d=mp';
    return image;
  }
}
