import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})

export class NewsService {

  constructor(private http: HttpClient) {}

  getNews() {
    return this.http.get(environment.apiUrl + '/news').pipe(
      tap(rssFeed => {
        //this._status.next(status);
        //console.log('RSS: vvv');
        //console.log(rssFeed);
      })
    );
  }

}
