<div class="container">
  <div class="trafficbox" fxLayout fxLayoutGap="5px">
    <!--<img src="https://www.cita.lu/info_trafic/cameras/images/cccam_6.jpg?~1030516240" height="200px" />
    <img src="https://www.cita.lu/info_trafic/cameras/images/cccam_3.jpg?~960383393" height="200px" /> -->

    <div class="weather">
      <sc-skycons
        class="icon"
        [weather]="weather?.icon"
        [color]="weather?.color"
      ></sc-skycons>
      <div class="summary">{{ weather?.summary }}</div>
      <div class="temperature">{{ weather?.temperature }} °C</div>
      <!-- <div class="feelslike">{{ weather?.feelslike }} °C</div> -->
    </div>

    <div class="camera">
      <video #video class="videoplayer" muted="muted" allow="autoplay"></video>
      <div class="legend">A6</div>
    </div>

    <div class="newsfeed" fxLayout="row" fxLayoutGap="5px">
      <div class="eventImageWrapper">
        <img class="eventImage" [src]="eventImage" />
        <img class="eventSourceLogo" [src]="feedLogoUrl" />
        <div class="eventSource">{{ feedTitle }}</div>
      </div>
      <div fxLayout="column" fxLayoutGap="5px">
        <div>{{ eventDate | date: 'dd.MM.y HH:mm'}}</div>
        <div class="eventTitle">{{ eventTitle }}</div>
        <div>{{ eventDetails }}</div>
      </div>
    </div>
  </div>
</div>
