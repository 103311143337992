<div class="container">
  <div fxFlex fxLayout fxLayoutGap="5px">
    <!--
    <div fxFlex>
      <div fxFlex fxLayout="column" fxLayoutGap="5px">
        <mat-card class="permabox" fxFlex *ngFor="let service of sapDutyList">
          <mat-card-title
            ><span class="service-name">{{ service.service }}</span>
            <span class="shift-time"
              >( {{ service.shift_start | slice: 0:5 }} -
              {{ service.shift_end | slice: 0:5 }} )</span
            ></mat-card-title
          >
          <mat-card-content>
            <mat-list>
              <mat-list-item
                *ngFor="let agent of service.agents; let i = index"
              >
                <div
                  [class]="agent.cgdisid == '' && i < 2 ? 'missing' : 'agent'"
                  fxLayout
                  fxFill
                  fxLayoutGap="10px"
                  *ngIf="i < 2 || agent.cgdisid != ''"
                >
                  <img
                    matListAvatar
                    fxFlex="0 1 auto"
                    fxFlexAlign="center"
                    [src]="getGravatarImage(agent.email)"
                  />

                  <div class="agentname" fxFlex fxFlexAlign="center">
                    {{ agent.lastname }} {{ agent.firstname }}
                  </div>

                  <div class="agentid" fxFlex="0 1 auto" fxFlexAlign="center">
                    {{ agent.cgdisid }}
                  </div>

                  <img
                    class="icon"
                    src="assets/icons/misc/cagr.png"
                    *ngIf="i == 0"
                  />
                  <img
                    class="icon"
                    src="assets/icons/misc/driver.png"
                    *ngIf="i == 1"
                  />
                  <img
                    class="icon"
                    src="assets/icons/misc/sap.png"
                    *ngIf="i == 2"
                  />
                </div>
              </mat-list-item>
            </mat-list>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    -->
    <mat-card class="permabox" fxFlex *ngFor="let service of incsaDutyList">
      <mat-card-title
        ><span class="service-name">{{ service.service }}</span>
        <span class="shift-time"
          >( {{ service.shift_start | slice: 0:5 }} -
          {{ service.shift_end | slice: 0:5 }} )</span
        ></mat-card-title
      >
      <mat-card-content>
        <mat-list>
          <mat-list-item *ngFor="let agent of service.agents; let i = index">
            <div
              [class]="
                agent.cgdisid == '' && i < 4 && service.servicetype == 2
                  ? 'missing'
                  : 'agent'
              "
              fxLayout
              fxFill
              fxLayoutGap="10px"
              *ngIf="
                (service.servicetype == 2 &&
                  (i < 6 || agent.cgdisid != '')) ||
                (service.servicetype == 3 && agent.cgdisid != '')
              "
            >
              <img
                matListAvatar
                fxFlex="0 1 auto"
                fxFlexAlign="center"
                [src]="getGravatarImage(agent.email)"
              />

              <div class="agentname" fxFlex fxFlexAlign="center">
                {{ agent.lastname }} {{ agent.firstname }}
              </div>

              <div class="agentid" fxFlex="0 1 auto" fxFlexAlign="center">
                {{ agent.cgdisid }}
              </div>

              <img
                class="icon"
                src="assets/icons/misc/csec.png"
                *ngIf="i == 0 && service.servicetype == 2"
              />

              <img
                class="icon"
                src="assets/icons/misc/driver.png"
                *ngIf="i == 1 && service.servicetype == 2"
              />

              <img
                class="icon"
                src="assets/icons/misc/cpel.png"
                *ngIf="i < 2 && service.servicetype == 3"
              />
              <img
                class="icon"
                src="assets/icons/misc/ccie.png"
                *ngIf="(i == 2 || i == 3) && service.servicetype == 3"
              />
              <img
                class="icon"
                src="assets/icons/misc/bin1.png"
                *ngIf="(i == 2 || i == 3) && service.servicetype == 2"
              />
              <img
                class="icon"
                src="assets/icons/misc/bin2.png"
                *ngIf="(i == 4 || i == 5) && service.servicetype == 2"
              />
            </div>
          </mat-list-item>
        </mat-list>
      </mat-card-content>
    </mat-card>
  </div>
</div>
