<div class="container" fxFlex>
  <div class="environmentbox" fxLayout fxLayoutGap="5px" fxFlex="1 1 100">
    <div class="waterlevels" fxLayout="column" fxLayoutGap="5px">
      <div class="rivername">{{ riverName }} ({{ measurePoint }})</div>
        <!--
      <div class="values">
        {{ current['value'] }}{{ current['unit'] }} @
        {{ current['timestamp'] | date: 'dd.MM.y HH:mm' }}
      </div>-->
      
        <canvas class="chart" fxFlex="1 1 100"
          baseChart
          [datasets]="lineChartData"
          [labels]="lineChartLabels"
          [options]="lineChartOptions"
          [colors]="lineChartColors"
          chartType="line"
        >
        </canvas>

    </div>
  </div>
</div>
