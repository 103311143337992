<div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">
  <mat-card class="box">
    <mat-card-header>
      <mat-card-title>Log in</mat-card-title>
    </mat-card-header>

    <mat-card-content>

      <div class="spinner" *ngIf="isLoading">
        <mat-progress-spinner
          color="accent"
          mode="indeterminate"
        ></mat-progress-spinner>
      </div>

      <form [formGroup]="loginForm" *ngIf="!isLoading" class="auth-form">
        <mat-form-field class="full-width">
          <input matInput formControlName="username" type="text" placeholder="Username" />
        </mat-form-field>

        <mat-form-field class="full-width">
          <input matInput formControlName="password" type="password" placeholder="Password" />
        </mat-form-field>
      </form>
    </mat-card-content>
    <button (click)="onLogin()" mat-stroked-button color="accent" class="btn-block">
      Log in
    </button>
  </mat-card>
</div>
