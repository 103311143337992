export const environment = {
  production: true,
  CISName: 'CIS Hobscheid',
  apiUrl: 'https://api.cish.lu',
  rssUrl: 'https://www.rtl.lu/rss/national',
  version: '1.1',

  dutyListUpdateTime: 30,
  infoNewsRotateTime: 30,
  infoWeatherUpdateTime: 900
};
