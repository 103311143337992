import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
  isLoading: boolean = false;
  error: string = null;

  loginForm: FormGroup = new FormGroup({
    username: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required)
  });

  constructor(
    private authService: AuthService,
    private router: Router,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {}

  onLogin() {
    if (this.loginForm.valid) {
      this.isLoading = true;
      let username = this.loginForm.get('username').value;
      let password = this.loginForm.get('password').value;
      this.error = null;

      this.authService.login(username, password).subscribe(
        response => {
          this.isLoading = false;
          this.router
            .navigateByUrl('/status')
            .then(status => {
              console.log('authenticate - router: status: ' + status);
            })
            .catch(error => {
              console.log('authenticate - router: error: ' + error);
            });

          console.log('authenticate: Routing finished');
          return true;
        },
        errRes => {
          this.isLoading = false;
          this.notificationService.warn('Erreur de login: ' + errRes.error);
          /*
        this.showAlert(
          this.translate.instant('LOGIN_FAILED_TITLE'),
          this.translate.instant(errRes.error)
        );*/
          return false;
        }
      );
      this.loginForm.reset();
    }
  }

  private showAlert(header: string, message: string) {
    /*this.alertCtrl
      .create({
        header,
        message,
        buttons: ['OK']
      })
      .then(alertEl => alertEl.present());*/
  }
}
