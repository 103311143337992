import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ServiceWorkerModule } from '@angular/service-worker';

import { environment } from '../environments/environment';

import { MaterialModule } from './modules/material/material.module';
import { SkyconsModule } from 'ngx-skycons'; 
import { ChartsModule } from 'ng2-charts';

import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { AuthComponent } from './components/auth/auth.component';
import { StatusComponent } from './components/status/status.component';
import { PermanenceComponent } from './components/status/permanence/permanence.component';
import { HospitalComponent } from './components/status/hospital/hospital.component';
import { EnvironmentComponent } from './components/status/environment/environment.component';
import { AuthInterceptorService } from './services/auth-interceptor.service';
import { TrafficComponent } from './components/status/traffic/traffic.component';

const appRoutes: Routes = [
  { path: '', component: AuthComponent },
  { path: 'login', component: AuthComponent },
  { path: 'status', component: StatusComponent },
  { path: 'hospital', component: HospitalComponent },
  { path: 'environment', component: EnvironmentComponent },
  { path: 'news', component: TrafficComponent }
];

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    PermanenceComponent,
    AuthComponent,
    StatusComponent,
    HospitalComponent,
    EnvironmentComponent,
    TrafficComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    HttpClientModule,
    MaterialModule,
    SkyconsModule,
    ChartsModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(appRoutes),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
