import { Component, OnInit } from '@angular/core';
import { EnvironmentService } from 'src/app/services/environment.service';
import { formatDate, registerLocaleData } from '@angular/common';
import { ChartOptions, ChartDataSets } from 'chart.js';

import localeFr from '@angular/common/locales/fr';
import { Label, Color } from 'ng2-charts';

registerLocaleData(localeFr);

@Component({
  selector: 'app-environment',
  templateUrl: './environment.component.html',
  styleUrls: ['./environment.component.scss'],
})
export class EnvironmentComponent implements OnInit {
  public lineChartData: ChartDataSets[] = [{ data: [], label: '' }];

  public lineChartLabels: Label[] = [];

  public lineChartOptions: ChartOptions & { annotation: any } = {
    responsive: true,
    annotation: {},
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          ticks: {
            fontColor: 'white',
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            fontColor: 'white',
          },
        },
      ],
    },
  };

  public lineChartColors: Color[] = [
    {
      backgroundColor: '#009fdb',
      borderColor: '#009fdb',
      pointBackgroundColor: 'white',
      pointBorderColor: '#009fdb',
    },
  ];

  public riverName = '';
  public measurePoint = '';
  public current = {};
  public minimum = {};
  public maximum = {};

  public data: any[] = [];
  public multi: [];
  public yScaleMax = 0;

  constructor(private environmentService: EnvironmentService) {}

  ngOnInit(): void {
    this.updateWaterlevels();
  }

  ngOnDestroy(): void {}

  updateWaterlevels(): void {
    this.environmentService.getWaterLevels().subscribe((levels) => {
      console.log(levels);
      this.riverName = levels['river']['name'];
      this.measurePoint = levels['city'];
      this.current = levels['current'];
      this.minimum = levels['minimum'];
      this.maximum = levels['maximum'];
      this.yScaleMax = levels['maximum']['value'];

      //this.lineChartData[0] = { data: [], label: levels['city'] };
      this.lineChartData[0]['label'] =
        levels['river']['name'] + ' (' + levels['city'] + ')';
      this.lineChartData[0]['data'] = [];
      this.lineChartLabels = [];
      levels['measurements'].forEach((serie) => {
        this.lineChartData[0]['data'] = [serie['value']].concat(
          this.lineChartData[0]['data']
        );

        this.lineChartLabels = [
          formatDate(serie['timestamp'], 'HH:mm', 'fr-LU'),
        ].concat(...this.lineChartLabels);
      });
    });
  }
}
