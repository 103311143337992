import { Component, OnInit } from '@angular/core';
import { StatusService } from 'src/app/services/status.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-hospital',
  templateUrl: './hospital.component.html',
  styleUrls: ['./hospital.component.scss']
})
export class HospitalComponent implements OnInit {
  private hospitalsSub: Subscription;
  public hospitals = [];

  constructor(private statusService: StatusService) { }

  ngOnInit(): void {

    this.hospitalsSub = this.statusService.hospitals.subscribe(response => {
      if('hospitals' in response) {
        this.hospitals = response.hospitals;
        //console.log(response.hospitals);
      }
    });
  }

  ngAfterViewInit(): void {
    this.statusService.getDutyHospitals().subscribe();
  }

  ngOnDestroy(): void {
    this.hospitalsSub.unsubscribe();
  }


}
