import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpHeaders
} from '@angular/common/http';
import { switchMap } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { from } from 'rxjs';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // return next.handle(req);

    if (!req.url.includes('http')) {
      console.log('HttpInterceptor: Local request ' + req.url);
      return next.handle(req);
    }
    console.log('HttpInterceptor: ' + req.url);
    const token = this.authService.token;

    if (!token) {
      console.log('HttpInterceptor: No token');
      return next.handle(req);
    }

    // console.log('HttpInterceptor: token found');
    const modifiedRequest = req.clone({
      headers: new HttpHeaders({
        Authorization: token,
        AppVersion: environment.version/*,
        Manufacturer: info.model,
        Device: info.model,
        Platform: info.platform,
        OsVersion: info.osVersion,
        Uuid: info.uuid*/
      })
    });
    return next.handle(modifiedRequest);
  }
}
