import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { Weather } from '../models/weather.model';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  constructor(private http: HttpClient) {}

  getWeather() {
    return this.http.get<Weather>(environment.apiUrl + '/weather');
  }

  getWaterLevels() {
    return this.http.get<Weather>(environment.apiUrl + '/waterlevels');
  }
}
