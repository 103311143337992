import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { Status, Hospitals } from '../models/status.model';

@Injectable({
  providedIn: 'root'
})
export class StatusService {
  private _status = new BehaviorSubject<Status>({
    available: false,
    hospital: '',
    dutylist: []
  });

  private _dutyHospitals = new BehaviorSubject<Hospitals>({
    hospitals: []
  });

  constructor(private http: HttpClient) {}

  get status() {
    return this._status.asObservable();
  }

  get hospitals() {
    return this._dutyHospitals.asObservable();
  }

  fetchStatus() {
    return this.http.get<Status>(environment.apiUrl + '/status').pipe(
      tap(status => {
        this._status.next(status);
        console.log('STATUS: vvv');
        console.log(status);
      })
    );
  }

  getDutyHospitals() {
    //console.log('Fetching hospital');
    return this.http.get<Hospitals>(environment.apiUrl + '/hospital').pipe(
      tap(hospital => {
        this._dutyHospitals.next(hospital);
      })
    );
  }

}
