import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { NewsService } from 'src/app/services/news.service';
import { EnvironmentService } from 'src/app/services/environment.service';
import { Weather } from 'src/app/models/weather.model';
import { take } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';

declare var Hls;

@Component({
  selector: 'app-traffic',
  templateUrl: './traffic.component.html',
  styleUrls: ['./traffic.component.scss'],
})
export class TrafficComponent implements OnInit {
  @ViewChild('video', { static: true }) videoElement: ElementRef;

  public feedTitle: String;
  public feedLogoUrl: String;

  public events: [] = [];
  public eventIndex = -1;
  public eventImage: String;
  public eventDate: Date;
  public eventTitle: String;
  public eventDetails: String;

  public weather: Weather;

  constructor(
    private newsService: NewsService,
    private environmentService: EnvironmentService
  ) {}

  ngOnInit(): void {
    console.log('ngOnInit');

    this.updateNews();
    setInterval(() => {
      this.rotateNews();
    }, environment.infoNewsRotateTime * 1000);

    this.updateWeather();
    setInterval(() => {
      this.updateWeather();
    }, environment.infoWeatherUpdateTime * 1000);

    var video = this.videoElement.nativeElement;
    if (Hls.isSupported()) {
      var hls = new Hls();
      hls.loadSource('https://live-edge.rtl.lu/cita/cita_a6/playlist.m3u8');
      hls.attachMedia(video);
      hls.on(Hls.Events.MANIFEST_PARSED, function () {
        video.play();
      });
    } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
      video.src = 'https://live-edge.rtl.lu/cita/cita_a6/playlist.m3u8';
      video.addEventListener('loadedmetadata', function () {
        video.play();
      });
    }
  }

  updateWeather(): void {
    this.environmentService
      .getWeather()
      .pipe(take(1))
      .subscribe((weather) => {
        this.weather = weather;
        if (weather.icon == 'clear-day') {
          this.weather.color = 'yellow';
        } else {
          this.weather.color = '#009fdb';
        }
      });
  }

  updateNews(): void {
    this.newsService.getNews().subscribe((response) => {
      this.feedTitle = response['title'];
      this.feedLogoUrl = response['image']['url'];

      this.eventIndex = -1;
      this.events = response['items'];
      this.rotateNews();
    });
  }

  rotateNews(): void {
    if (this.events.length == 0) {
      this.updateNews();
      return;
    }
    this.eventIndex++;
    if (this.eventIndex >= this.events.length) {
      this.eventIndex = 0;
      this.updateNews();
    }

    const currentItem = this.events[this.eventIndex];
    /*
    console.log(
      'vvv Showing ' + (this.eventIndex + 1) + ' of ' + this.events.length
    );
    console.log(currentItem);*/
    this.eventDate = currentItem['pubDate'];
    if (currentItem['enclosure'] && currentItem['enclosure']['url']) {
      this.eventImage = currentItem['enclosure']['url'];
    } else {
      this.eventImage = 'assets/img/no_picture.png';
    }

    this.eventTitle = currentItem['title'];
    this.eventDetails = currentItem['content'];
  }
}
