import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {
  private _token = null;
  private _tokenExpirationDate = null;
  private activeLogoutTimer: any;

  get token() {
    const jwt = new JwtHelperService();
    const expirationTime = new Date(jwt.getTokenExpirationDate(this._token));
    // console.log(expirationTime);
    if (!expirationTime || expirationTime <= new Date()) {
      console.log('get token: No valid token');
      return null;
    }
    // console.log('get token: Valid token returned');
    return this._token;
  }

  get tokenDuration() {
    if (!this.token) {
      return 0;
    }

    return this._tokenExpirationDate.getTime() - new Date().getTime();
  }

  get userIsAuthenticated() {
    if (this.token) {
      return true;
    } else {
      return false;
    }
  }

  get getDisplayname() {
    if (this.token) {
      const jwt = new JwtHelperService();
      const decodedToken = jwt.decodeToken(this.token);
      if (
        decodedToken.payload &&
        decodedToken.payload.firstname &&
        decodedToken.payload.lastname
      ) {
        return (
          decodedToken.payload.firstname + ' ' + decodedToken.payload.lastname
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  constructor(private http: HttpClient, private router: Router) {}

  autoLogin() {
    const token = localStorage.getItem('token');
    if (token === null) {
      console.log('Autologin: No valid token found');
      return null;
    }
    console.log('Autologin: processing stored data');
    this._token = token;

    const jwt = new JwtHelperService();
    const expirationTime = new Date(jwt.getTokenExpirationDate(this._token));
    if (expirationTime <= new Date()) {
      return null;
    }
    this.autoLogout(expirationTime.getTime() - new Date().getTime());

    return true;
  }

  login(username: string, password: string) {
    //console.log('Login: start');
    //console.log('username: ' + username + ' password: ' + password);
    return this.http
      .post<any>(environment.apiUrl + '/auth', {
        cgdisid:username,
        password
      })
      .pipe(
        tap(resData => {
          console.log('login: login-tap');
          this._token = resData.token;

          const jwt = new JwtHelperService();
          const expirationTime = jwt.getTokenExpirationDate(this._token);
          this.autoLogout(expirationTime.getTime() - new Date().getTime());
          this.storeToken(this._token);
        })
      );
  }

  private storeToken(token: string) {
    localStorage.setItem('token', token);
  }

  logout() {
    console.log('Logout');
    if (this.activeLogoutTimer) {
      clearTimeout(this.activeLogoutTimer);
    }
    localStorage.removeItem('token');
    this._token = null;
    this._tokenExpirationDate = null;
    this.router.navigateByUrl('/auth');
  }

  private autoLogout(duration: number) {
    console.log('Autologout in ' + +duration / 1000 / 60 / 60 + ' hours');
    if (this.activeLogoutTimer) {
      clearTimeout(this.activeLogoutTimer);
    }
    this.activeLogoutTimer = setTimeout(() => {
      this.logout();
    }, duration);
  }

  ngOnDestroy() {
    if (this.activeLogoutTimer) {
      clearTimeout(this.activeLogoutTimer);
    }
  }
}
